import { storableError } from '../../util/errors';
import { createImageVariantConfig } from '../../util/sdkLoader';
import { parse } from '../../util/urlHelpers';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { denormalisedEntities } from '../../util/data';

// Pagination page size might need to be dynamic on responsive page layouts
const RESULT_PAGE_SIZE = 42;

// ================ Action types ================ //

export const FETCH_PURCHASES_REQUEST = 'app/MyGuidesPage/FETCH_PURCHASES_REQUEST';
export const FETCH_PURCHASES_SUCCESS = 'app/MyGuidesPage/FETCH_PURCHASES_SUCCESS';
export const FETCH_PURCHASES_ERROR = 'app/MyGuidesPage/FETCH_PURCHASES_ERROR';

// ================ Reducer ================ //

const initialState = {
  pagination: null,
  queryParams: null,
  queryInProgress: false,
  queryPurchasesError: null,
  currentPageResultIds: [],
};

const resultIds = data => data.data.map(p => p.id);

const myGuidesPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_PURCHASES_REQUEST:
      return {
        ...state,
        queryParams: payload.queryParams,
        queryInProgress: true,
        queryPurchasesError: null,
        currentPageResultIds: [],
      };
    case FETCH_PURCHASES_SUCCESS:
      return {
        ...state,
        currentPageResultIds: resultIds(payload.data),
        pagination: payload.data.meta,
        queryInProgress: false,
      };
    case FETCH_PURCHASES_ERROR:
      console.error('Reducer ERROR payload:', payload); // Debugging line
      return {
        ...state,
        queryInProgress: false,
        queryPurchasesError: payload
      };

    default:
      return state;
  }
};


export default myGuidesPageReducer;

// ================ Action creators ================ //

export const queryPurchasesRequest = queryParams => ({
  type: FETCH_PURCHASES_REQUEST,
  payload: { queryParams },
});

export const queryPurchasesSuccess = response => ({
  type: FETCH_PURCHASES_SUCCESS,
  payload: { data: response.data },
});

export const queryPurchasesError = e => ({
  type: FETCH_PURCHASES_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const queryUserPurchases = (queryParams, config) => (dispatch, getState, sdk) => {
  dispatch(queryPurchasesRequest(queryParams));

  const { perPage, ...rest } = queryParams;
  const params = { ...rest, perPage, only: 'order', include: ['listing'] };
  let listingIds = []
  return sdk.transactions
    .query(params)
    .then(async response => {
      const transactions = response.data.data
      transactions.forEach(transaction => {
        let showGuideMap = false
        const transitions = transaction.attributes.transitions

        if (transitions.map(({ transition }) => transition).includes("transition/confirm-payment")) {
          showGuideMap = true
        }
        if (transitions.map(({ transition }) => transition).includes("transition/cancel")) {
          showGuideMap = false
        } else if (transitions.map(({ transition }) => transition).includes("transition/auto-cancel")) {
          showGuideMap = false
        } else if (transitions.map(({ transition }) => transition).includes(" transition/cancel-from-disputed")) {
          showGuideMap = false
        }
        if (showGuideMap) {
          const listingId = transaction.relationships?.listing?.data?.id.uuid
          listingIds.push(listingId)
        }
      })

      const {
        aspectWidth = 1,
        aspectHeight = 1,
        variantPrefix = 'listing-card',
      } = config?.layout?.listingImage;
      const aspectRatio = aspectHeight / aspectWidth;

      const listings = await sdk.listings.query({
        ids: listingIds,
        include: ['author', 'images'],
        'fields.listing': [
          'title',
          'geolocation',
          'price',
          'publicData.listingType',
          'publicData.transactionProcessAlias',
          'publicData.unitType',
          // These help rendering of 'purchase' listings,
          // when transitioning from search page to listing page
          'publicData.pickupEnabled',
          'publicData.shippingEnabled',
        ],
        'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
        'fields.image': [
          'variants.scaled-small',
          'variants.scaled-medium',
          `variants.${variantPrefix}`,
          `variants.${variantPrefix}-2x`,
        ],
        ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
        ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
        'limit.images': 1,
      })

      const listingFields = config?.listing?.listingFields;
      const sanitizeConfig = { listingFields };

      dispatch(addMarketplaceEntities(listings, sanitizeConfig));
      dispatch(queryPurchasesSuccess(listings));
      return listings;
    })
    .catch(e => {
      dispatch(queryPurchasesError(storableError(e)));
      throw e;
    });
};

export const loadData = (params, search, config) => {
  const queryParams = parse(search);
  const page = queryParams.page || 1;

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'purchase-card',
  } = config.layout.listingImage;
  const aspectRatio = aspectHeight / aspectWidth;

  return queryUserPurchases({
    ...queryParams,
    page,
    perPage: RESULT_PAGE_SIZE,
    include: ['listing'],
    'fields.image': [`variants.${variantPrefix}`, `variants.${variantPrefix}-2x`],
    ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    'limit.images': 1,
  }, config);
};

// Selector
export const getPurchasesById = (state, purchaseIds) => {
  const { marketplaceData } = state;
  const resources = purchaseIds.map(id => ({
    id,
    type: 'transaction',
  }));
  const throwIfNotFound = false;
  return denormalisedEntities(marketplaceData, resources, throwIfNotFound);
};
