import React, { useEffect, useState } from "react";

import css from './TipCard.module.css'
import ImageCarousel from "../../containers/ListingPage/ImageCarousel/ImageCarousel";
import ReactImageGallery from "react-image-gallery";
import IconArrowHead from "../IconArrowHead/IconArrowHead";
import fullScreenIcon from './fullscreen.svg'
import minimizeScreenIcon from './minimizescreen.svg'
import editIcon from './editIcon.svg';
import deleteIcon from './deleteIcon.svg';
import classNames from "classnames";
import * as geocoderGoogleMaps from '../LocationAutocompleteInput/GeocoderGoogleMaps';

const TipCard = props => {
    const { place, onRemove, onEdit, className } = props
    const [photosForDisplay, setPhotosForDisplay] = useState([])

    const { location, category, description, gpx_link, vanspot_location } = place
    const { address, icon, name, photos, website, url, formatted_phone_number, place_id } = location?.selectedPlace || {}

    useEffect(() => {
        // getPhotos()
        return () => {

        };
    }, [place]);
    const getPhotos = async () => {
        if (place) {
            const Geocoder = new geocoderGoogleMaps.default
            const placeDetails = await Geocoder.getPlacesPhotos(place_id)
            setPhotosForDisplay(placeDetails?.photos || [])
        }
    }
    const IMAGE_GALLERY_OPTIONS = {
        showPlayButton: false,
        showThumbnails: false,
        showFullscreenButton: true,
    };


    const renderLeftNav = (onClick, disabled) => {
        return (
            <button className={css.navLeft} disabled={disabled} onClick={onClick}>
                <div className={css.navArrowWrapper}>
                    <IconArrowHead direction="left" size="big" className={css.arrowHead} />
                </div>
            </button>
        );
    };

    const renderRightNav = (onClick, disabled) => {
        return (
            <button className={css.navRight} disabled={disabled} onClick={onClick}>
                <div className={css.navArrowWrapper}>
                    <IconArrowHead direction="right" size="big" className={css.arrowHead} />
                </div>
            </button>
        );
    };

    const renderFullscreenButton = (onClick, isFullscreen) => {
        return (
            <button className={css.fullScreen} onClick={onClick}>
                <img src={isFullscreen ? minimizeScreenIcon : fullScreenIcon} style={{ width: isFullscreen ? "30px" : "15px" }} />
            </button>
        );
    }

    const actionButton = (icon, onClick) => {
        return (
            <button className={css.actionButton} onClick={onClick}>
                <img src={icon} style={{ width: '20px' }} />
            </button>
        )
    }
    return <div className={classNames(css.card, className)}>
        {/* <ReactImageGallery
            additionalClass={css.photoSlider}
            items={photosForDisplay.map(photo => {
                return {
                    renderItem: () => <div className={css.photoWrapper}>
                        <img className={css.photo} src={photo} />
                    </div>,
                    thumbnail: photo,
                }
            })}
            renderRightNav={renderRightNav}
            renderLeftNav={renderLeftNav}
            renderFullscreenButton={renderFullscreenButton}
            {...IMAGE_GALLERY_OPTIONS}
        /> */}
        <div className={css.cardContent}>
            <div className={css.category}>{category}</div>
            <h6>{name}</h6>
            <div> <a href={url} target="_blank">{address}</a></div>
            <div><a href={`callto:${formatted_phone_number}`}>{formatted_phone_number}</a></div>
            {website && <div>
                <a href={website} target="_blank">Website</a>
            </div>}
            {gpx_link && <div>
                 <a href={gpx_link} target="_blank">GPX</a>
            </div>}
            {vanspot_location && <div>
                 <a href={vanspot_location} target="_blank">Van Spot Location</a>
            </div>}
            <p className={css.description}>{description}</p>
            
            <div className={css.actionSection}>
                {onEdit && actionButton(editIcon, onEdit)}
                {onRemove && actionButton(deleteIcon, onRemove)}
            </div>
        </div>
    </div>
}

export default TipCard